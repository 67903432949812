exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-annexes-annexes-json-slug-js": () => import("./../../../src/pages/annexes/{annexesJson.slug}.js" /* webpackChunkName: "component---src-pages-annexes-annexes-json-slug-js" */),
  "component---src-pages-articles-articles-json-slug-js": () => import("./../../../src/pages/articles/{articlesJson.slug}.js" /* webpackChunkName: "component---src-pages-articles-articles-json-slug-js" */),
  "component---src-pages-articles-index-js": () => import("./../../../src/pages/articles/index.js" /* webpackChunkName: "component---src-pages-articles-index-js" */),
  "component---src-pages-comparisons-basic-law-js": () => import("./../../../src/pages/comparisons/basic-law.js" /* webpackChunkName: "component---src-pages-comparisons-basic-law-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laws-laws-json-slug-js": () => import("./../../../src/pages/laws/{lawsJson.slug}.js" /* webpackChunkName: "component---src-pages-laws-laws-json-slug-js" */),
  "component---src-pages-laws-laws-json-slug-recitals-js": () => import("./../../../src/pages/laws/{lawsJson.slug}-recitals.js" /* webpackChunkName: "component---src-pages-laws-laws-json-slug-recitals-js" */),
  "component---src-pages-news-contentful-news-post-slug-js": () => import("./../../../src/pages/news/{contentfulNewsPost.slug}.js" /* webpackChunkName: "component---src-pages-news-contentful-news-post-slug-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-privacy-notice-index-js": () => import("./../../../src/pages/privacy-notice/index.js" /* webpackChunkName: "component---src-pages-privacy-notice-index-js" */),
  "component---src-pages-terms-of-use-index-js": () => import("./../../../src/pages/terms-of-use/index.js" /* webpackChunkName: "component---src-pages-terms-of-use-index-js" */),
  "component---src-templates-chapter-template-js": () => import("./../../../src/templates/chapterTemplate.js" /* webpackChunkName: "component---src-templates-chapter-template-js" */)
}

